<template>
  <div>
    <div id="pageChronology" class="mainContents">
      <UserNavSp :page="'timetable'" />

      <UserTitle :user="user" />

      <UserNav :user="user" :page="'timetable'" />

      <div v-if="isOwner" class="editButton">
        <p>
          <router-link v-if="isOwner" :to="{ name: 'timetable-edit' }">
            年表を編集する
          </router-link>
        </p>
      </div>

      <template v-if="timetable.display">
        <div class="chronologyArea">
          <h2>{{ user.full_name }} 年表</h2>
          <LikeButtonP
            ref="likeButton"
            :object="timetable"
            object-type="TIMETABLE"
          />
          <table>
            <tr v-for="(ty, key) in validTimetableYears()" :key="key">
              <td>
                <div class="wrap">
                  <p class="year">
                    <strong>{{ ty.year }}</strong><span>{{ jyear(ty.year) }}</span>
                  </p>
                  <p class="affair">
                    {{ affair(ty.year) }}
                  </p>
                </div>
              </td>
              <td>
                <p class="age">
                  <span>{{ age(ty.year) }}</span>
                </p>
              </td>
              <td>
                <p class="comment" v-html="ty.comment" />
                <p v-if="ty.image" class="image">
                  <img
                    :src="ty.image + '?d=200x200'"
                    alt=""
                    class="obj-fit-img"
                  >
                </p>
              </td>
            </tr>
          </table>

          <!-- /.chronologyArea -->
        </div>

        <div class="snsArea">
          <div class="inner">
            <LikeButtonP
              ref="likeButton"
              :object="timetable"
              object-type="TIMETABLE"
            />
            <SnsButtons :text="pageTitle" :url="pageUrl" />
          </div>
          <!-- /.snsArea -->
        </div>

        <div v-if="timetable.allow_comments" class="messageArea">
          <div class="inner">
            <template v-if="!loggedIn">
              コメントするにはログインしてください
            </template>
            <template v-if="loggedIn">
              <h2>
                この記事にコメントする
              </h2>
              <form>
                <div class="textareaFrame">
                  <textarea v-model="comment" placeholder="コメント入力…" />
                </div>
                <p class="button">
                  <button
                    type="submit"
                    :disabled="formInvalid"
                    @click="saveComment($event)"
                  >
                    送信
                  </button>
                </p>
              </form>
            </template>
            <ul class="messageList">
              <BaseCommentCard
                v-for="c in timetableComment.comments"
                :key="c.id"
                :comment="c.node"
                :type="'timetable'"
                :owner="timetable.user"
              />
            </ul>
          </div>
          <div v-if="timetableComment.pageInfo.hasNextPage" class="buttonArea">
            <p>
              <button type="submit" @click="fetchMoreComments($event)">
                もっと見る
              </button>
            </p>
            <!-- /.buttonArea -->
          </div>
          <!-- /.messageArea -->
        </div>
      </template>
      <template v-else>
        <div class="nwArea">
          <p>現在、制作中</p>
        </div>
      </template>

      <!-- /.mainContents -->
    </div>
  </div>
</template>
<script>
import { authComputed } from '@/store/helpers.js'
import { mapState, mapGetters } from 'vuex'
import LikeButtonP from '@/components/LikeButtonP.vue'
import SnsButtons from '@/components/SnsButtons.vue'
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'
import { ageAt } from '@/helpers/timetable.js'

export default {
  components: {
    LikeButtonP,
    SnsButtons,
    UserTitle,
    UserNav,
    UserNavSp
  },
  props: {
    u: {
      type: Object,
      required: true
    },
    timetable: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: {},
      isUploading: false,
      comment: '',
      likes: []
    }
  },
  computed: {
    ...authComputed,
    ...mapState(['timetableComment']),
    ...mapGetters({
      getYearAffairByYear: 'yearAffair/getYearAffairByYear'
    }),
    isOwner: function () {
      return this.$store.getters.isOwner(this.$route.params.id)
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.comment) {
        return true
      }

      if (this.comment.trim() === '') {
        return true
      }

      return false
    },
    liked: function () {
      if (this.loggedIn) {
        return this.timetable.has_liked
      }
      return this.likes.includes(this.timetable.id)
    },
    pageTitle: function () {
      return this.user.full_name + 'さんの年表'
    },
    pageUrl: function () {
      return window.location.href
    }
  },
  created: function () {
    this.user = this.u
    this.$store.dispatch('yearAffair/fetchYearAffairs')
  },
  beforeDestroy () {},
  mounted () {
    this.fetchComments()
    if (localStorage.getItem('noLoginTimetableLikes')) {
      try {
        this.likes = JSON.parse(localStorage.getItem('noLoginTimetableLikes'))
      } catch (e) {
        localStorage.removeItem('noLoginTimetableLikes')
      }
    }
  },
  methods: {
    validTimetableYears () {
      return this.timetable.years.filter(ty => ty.comment !== '')
    },
    jyear (year) {
      const yearAffair = this.getYearAffairByYear(year)
      if (yearAffair) {
        return yearAffair.jyear
      }
      return null
    },
    affair (year) {
      const yearAffair = this.getYearAffairByYear(year)
      if (yearAffair) {
        return yearAffair.affair
      }
      return null
    },
    age (year) {
      if (this.user.birthday_display) {
        return ageAt(year, this.user.birthday)
      }
      return '-'
    },
    saveComment (event) {
      event.preventDefault()
      this.isUploading = true
      this.$store
        .dispatch('timetableComment/postComment', {
          id: this.timetable.id,
          comment: this.comment
        })
        .then(() => {
          this.comment = ''
          this.isUploading = false
        })
        .catch(() => {
          this.isUploading = false
        })
    },
    fetchComments () {
      if (!this.timetable.id) {
        return
      }
      this.$store
        .dispatch('timetableComment/fetchComments', {
          id: this.timetable.id,
          first: 12,
          add: false
        })
        .then(() => {})
    },
    fetchMoreComments (event) {
      if (!this.timetable.id) {
        return
      }
      this.$store
        .dispatch('timetableComment/fetchComments', {
          id: this.timetable.id,
          first: 12,
          after: this.timetableComment.pageInfo.endCursor,
          add: true
        })
        .then(() => {})
    }
  }
}
</script>
<style scoped></style>
