<template>
  <p v-if="!liked" class="favorite">
    <a @click="like">{{ object.likes }}</a>
  </p>
  <p v-else class="favorite active">
    <a class="active" @click="unlike">{{ object.likes }}</a>
  </p>
</template>
<script>
import { authComputed } from '@/store/helpers.js'

export default {
  name: 'LikeButton',
  props: {
    object: {
      type: Object,
      required: true
    },
    objectType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      likes: []
    }
  },
  computed: {
    ...authComputed,
    liked: function () {
      if (this.loggedIn) {
        return this.object.has_liked
      }
      return this.likes.includes(this.object.id)
    }
  },
  mounted () {
    if (localStorage.getItem('noLoginLikes' + this.objectType)) {
      try {
        this.likes = JSON.parse(
          localStorage.getItem('noLoginLikes' + this.objectType)
        )
      } catch (e) {
        localStorage.removeItem('noLoginLikes' + this.objectType)
      }
    }
  },
  methods: {
    like () {
      if (this.likes.includes(this.object.id)) {
        return
      }
      this.$store
        .dispatch(this.objectType.toLowerCase() + '/like', {
          id: this.object.id,
          action: 'LIKE',
          type: this.objectType
        })
        .then(res => {
          this.object.likes = res.like.likes
          this.object.has_liked = true
          if (!this.loggedIn) {
            try {
              this.likes = JSON.parse(
                localStorage.getItem('noLoginLikes' + this.objectType)
              )
              this.likes.push(this.object.id)
              const parsed = JSON.stringify(this.likes)
              localStorage.setItem('noLoginLikes' + this.objectType, parsed)
            } catch (e) {
              this.likes = []
              this.likes.push(this.object.id)
              const parsed = JSON.stringify(this.likes)
              localStorage.setItem('noLoginLikes' + this.objectType, parsed)
            }
          }
        })
    },
    unlike () {
      this.$store
        .dispatch(this.objectType.toLowerCase() + '/like', {
          id: this.object.id,
          action: 'UNLIKE',
          type: this.objectType
        })
        .then(res => {
          this.object.likes = res.like.likes
          this.object.has_liked = false
          if (!this.loggedIn) {
            try {
              this.likes = JSON.parse(
                localStorage.getItem('noLoginLikes' + this.objectType)
              )

              var index = this.likes.indexOf(this.object.id)
              this.likes.splice(index, 1)

              const parsed = JSON.stringify(this.likes)
              localStorage.setItem('noLoginLikes' + this.objectType, parsed)
            } catch (e) {
              this.likes = []
              const parsed = JSON.stringify(this.likes)
              localStorage.setItem('noLoginLikes' + this.objectType, parsed)
            }
          }
        })
    }
  }
}
</script>
