import { render, staticRenderFns } from "./UserTimetable.vue?vue&type=template&id=60405172&scoped=true&"
import script from "./UserTimetable.vue?vue&type=script&lang=js&"
export * from "./UserTimetable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60405172",
  null
  
)

export default component.exports