<template>
  <ul>
    <li class="facebook">
      <a :href="facebookUrl" target="_blank" rel="noopener">Facebookでシェア</a>
    </li>
    <li class="twitter">
      <a :href="twitterUrl" target="_blank" rel="noopener">twitterでツイート</a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    pageTitle: function () {
      return this.e.title
    },
    twitterUrl: function () {
      return (
        'http://twitter.com/share?text=' +
        encodeURI(this.text) +
        '&url=' +
        encodeURI(this.url) +
        '&hashtags='
      )
    },
    facebookUrl: function () {
      return 'http://www.facebook.com/share.php?u='
    }
  }
}
</script>
